import { useForm } from 'react-hook-form';
import { CachePolicies, useFetch } from 'use-http';
import { navigate } from 'gatsby';
import { useLocalStorage } from 'react-use';
import { routes } from '@/webapi/routes';
import { Pages } from '@/webapi/pages';

interface LoginResponse {
  sessionId: string;
}

export function useLogin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { post, error, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: false,
    },
  }));

  const [lastEmail, setLastEmail] = useLocalStorage(`vsly_login_email`, ``);

  const onSubmit = async ({ email }: { email: string }) => {
    const { sessionId } = (await post(routes.login(), {
      email,
    })) as LoginResponse;
    if (sessionId) {
      setLastEmail(email);
      await navigate(Pages.AWAIT_EMAIL, {
        state: { email, sessionId, type: `login` },
      });
    }
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    error,
    loading,
    errors,
    lastEmail,
  };
}
