import React, { FC, useContext, useEffect } from 'react';

import { useLogin } from '@/features/signup/login/useLogin';
import { FloatingInput } from '@/components/floating-label-text-input';
import { BigButton } from '@/components/big-button';
import { AccountContext } from '@/features/account-context';
import { Pages } from '@/webapi/pages';
import { getTimeOfDayGreeting } from '@/utils/time-utils';
import { SignupLayout } from '@/features/signup/shared/layout';
import { SignupBoxForm } from '@/features/signup/shared/box';
import {
  SignupErrorMessage,
  SignupLogo,
  SignupSubtitle,
  SignupTitle,
} from '@/features/signup/shared/components';
import { VSpace } from '@/components/spacing';
import { hideLoader, showLoader } from '@/components/PageLoader';
import { nav } from '@/utils/browser';

export const LoginForm: FC = () => {
  const { isLoggedIn } = useContext(AccountContext);
  const { register, onSubmit, loading, errors, error, lastEmail } = useLogin();

  useEffect(() => {
    if (isLoggedIn && isLoggedIn()) {
      nav(Pages.DASHBOARD);
    }
  });

  useEffect(() => {
    if (loading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [loading]);

  return (
    <SignupLayout>
      <SignupBoxForm onSubmit={onSubmit}>
        <SignupLogo />
        <VSpace value={2} />
        <SignupTitle>{getTimeOfDayGreeting()}</SignupTitle>
        <SignupSubtitle>
          Enter store email or associated user email
        </SignupSubtitle>
        <VSpace value={1} />
        <FloatingInput err={!!errors.email}>
          <input
            data-testid="email-input"
            placeholder="Your Email"
            defaultValue={lastEmail}
            type="email"
            id="input__email"
            name="email"
            disabled={loading}
            {...register(`email`, { required: true })}
          />
          <label htmlFor="input__email" data-content="Your Email" />
          {errors.email && <span>This field is required</span>}
        </FloatingInput>
        <BigButton
          border="2px solid black"
          noTransform
          size="xl"
          fillWidth
          data-testid="submit-btn"
          type="submit"
          disabled={loading}
        >
          Sign In
        </BigButton>
        {error && (
          <SignupErrorMessage>Oops.. something went wrong</SignupErrorMessage>
        )}
      </SignupBoxForm>
    </SignupLayout>
  );
};
