export function getTimeOfDayGreeting(): string {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr >= 5 && curHr < 12) {
    return `Good Morning`;
  }
  if (curHr >= 12 && curHr < 18) {
    return `Good Afternoon`;
  }
  if (curHr >= 18 && curHr < 21) {
    return `Good Evening`;
  }
  return `Good Night`;
}
